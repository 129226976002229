import faqServices from '../../../services/faq'

export default {
    data(){
        return {
            items: []
        }
    },
    mounted() {
        this.fetchData()
    },
    methods: {
        fetchData() {
            faqServices.findFaqs().then(resp=>{
                this.items = resp.data.d
            })
        }
    }
}